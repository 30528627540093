export function numberToStringWithDecimals(numericValue: number, decimals = 8) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(numericValue);
};

export function stringToNumber(stringValue: string) {
  if (stringValue === '.' || stringValue === '') {
    return 0;
  }
  return parseFloat(stringValue.replace(/,/g, ''));
};
