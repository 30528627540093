import React, { memo } from 'react';
import { Text } from '../../components';
import './Button.scss';

type ButtonProps = {
  label: string,
  onClick: () => void,
  isLoading?: boolean,
  isDisabled?: boolean,
  className?: string,
  size: 'sm' | 'md' | 'lg',
}

export const Button = memo(
  function Button(props: ButtonProps) {
    const {
      label,
      onClick,
      isLoading,
      isDisabled,
      className,
      size,
    } = props;

    return (
      <button
        onClick={onClick}
        className={`custom-btn background-second-color ${className} ${size}-btn`}
        disabled={isDisabled || isLoading}
      >
        <Text variant="caption2" weight={700}>
          {label.toUpperCase()}
        </Text>
      </button>
    );
  }
);