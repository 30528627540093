import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context';
import { Button, InputField, Text, CurrencyDropdown } from '../../components';
import graph from '../../assets/crypto/trend/graph.png';
import { numberToStringWithDecimals, stringToNumber } from '../../utils';
import './CryptoTrend.scss';

type Props = {
  id: string
  onCheckout: () => void,
};

export const CryptoTrend = ({ onCheckout, id }: Props) => {
  const { t } = useTranslation();
  const { amountValue, setAmountValue } = useContext(AppContext);
  const [ rawValue, setRawValue ] =
    useState<string>(numberToStringWithDecimals(amountValue));

  const onAmountChange = (value: string) => {
    if (value.match(/^\d*\.?\d*$/)) {
      setRawValue(value);
    }
  };

  const onInputBlur = () => {
    if (rawValue) {
      const valueAsNumber = stringToNumber(rawValue);
      setRawValue(numberToStringWithDecimals(valueAsNumber));
      setAmountValue(valueAsNumber);
    }
  };

  return (
    <section id={id} className="crypto-trend">
      <div className="graph-container display-flex-column align-items-center gap-10">
        <Text variant="content2" color="secondary" weight={600}>
          {t('buyPage.cryptoTrend.yourAmount').toUpperCase()}
        </Text>

        <div className="display-flex justify-center align-items-center gap-10 mb-30">
          <CurrencyDropdown size="sm" lightMode/>
          <InputField
            value={rawValue}
            onChange={onAmountChange}
            onBlur={onInputBlur}
            placeholder="Amount"
            lightMode
            size="md"
          />
        </div>

        <img src={graph} alt="currency trending" className="d-block"/>

        <Button
          label={t('buyPage.cryptoTrend.checkoutButton')}
          onClick={onCheckout}
          size="lg"
          isDisabled={!rawValue}
        />
      </div>
    </section>
  );
};