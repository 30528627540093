import { Text } from '../../Text/Text';
import './FeatureCard.scss';

export type FeatureCardType = {
  icon: JSX.Element
  title: string,
  description: string,
}

export const FeatureCard = ({ feature }: { feature: FeatureCardType }) => {
  return (
    <div className="feature-card">
      <div className="display-flex gap-10 mb-10 align-left">
        <div>
          {feature.icon}
        </div>
        <Text variant="content1" weight={600} align="left">
          {feature.title}
        </Text>
      </div>
      <Text variant="content2" hasOpacity align="left" maxLines={3}>
        {feature.description}
      </Text>
    </div>
  );
};