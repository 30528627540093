import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CurrencyDropdown, InputField, Text } from '../../components';
import { AppContext } from '../../context';
import { numberToStringWithDecimals, stringToNumber } from '../../utils';
import './BuyCrypto.scss';

type Props = {
  id: string
  onSubmit: () => void,
};

export const BuyCrypto = ({ onSubmit, id }: Props) => {
  const { t } = useTranslation();
  const { setAmountValue } = useContext(AppContext);
  const [ rawValue, setRawValue ] = useState<string>('');

  const onAmountChange = (value: string) => {
    if (value.match(/^\d*\.?\d*$/)) {
      setRawValue(value);
    }
  };

  const onInputBlur = () => {
    if (rawValue) {
      const valueAsNumber = stringToNumber(rawValue);
      setRawValue(numberToStringWithDecimals(valueAsNumber));
      setAmountValue(valueAsNumber);
    }
  };

  return (
    <section id={id} className="buy-crypto">
      <div className="form-container display-flex-column align-items-center">
        <Text variant="title2" weight={700} className="mb-30 mt-30 section-title">
          {t('buyPage.title')}
        </Text>

        <Text variant="content2" hasOpacity className="mb-30">
          {t('buyPage.subtitle')}
        </Text>

        <InputField
          value={rawValue}
          onChange={onAmountChange}
          onBlur={onInputBlur}
          placeholder="Amount"
          className="mb-30"
        />

        <CurrencyDropdown className="mb-30"/>

        <Button
          label={`${t('buyPage.proceedButton')}`}
          onClick={onSubmit}
          size="lg"
          isDisabled={!rawValue}
        />
      </div>
    </section>
  );
};