import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MiniCryptoIcons } from './MiniCryptoIcons/MiniCryptoIcons';
import { Text, Button } from '../../components';
import { Routes } from '../../router';
import bitcoin1 from '../../assets/crypto/coins/Bitcoin-1.png';
import monero1 from '../../assets/crypto/coins/Monero-1.png';
import etherium2 from '../../assets/crypto/coins/Etherium-2.png';
import litecoin2 from '../../assets/crypto/coins/LiteCoin-2.png';
import './CreditToCrypto.scss';

export const CreditToCrypto = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBuyNow = () => {
    navigate(Routes.BUY_PAGE);
  };

  return (
    <section
      id={id}
      className="credit-to-crypto main-background-image display-flex-column align-items-center mb-40"
    >
      <Text variant="title2" weight={700} className="mb-30 mt-30 section-title">
        {t('landingPage.title')}
      </Text>

      <div className="timer-container mb-30">
        <Text variant="subtitle2" color="secondary">
          02 : 12 : 23 : 35
        </Text>
      </div>

      <Button label={t('layout.button.buyNow')} onClick={onBuyNow} size="lg" className="mb-30"/>

      <MiniCryptoIcons/>

      <img src={bitcoin1} alt="bitcoin" className="coin img-top-left"/>
      <img src={litecoin2} alt="litecoin" className="coin img-top-right"/>
      <img src={etherium2} alt="ehterium" className="coin img-bottom-left"/>
      <img src={monero1} alt="monero" className="coin img-bottom-right"/>
    </section>
  );
};