import React, { useContext, useMemo } from 'react';
import { CryptoList, CryptoValue } from '../../constants';
import { Dropdown } from '../../components';
import { AppContext } from '../../context';

type Props = {
  lightMode?: boolean;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

export const CurrencyDropdown = ({ lightMode, size, className }: Props) => {
  const {
    selectedCrypto,
    setSelectedCrypto,
  } = useContext(AppContext);

  const options = useMemo(() => (
    // @ts-ignore
    Object.keys(CryptoList).map((key) => (CryptoList[key].symbol))
  ), []);

  const onSelect = (value: string) => {
    // @ts-ignore
    setSelectedCrypto(CryptoValue[value]);
  };

  return (
    <Dropdown
      value={CryptoList[selectedCrypto].symbol}
      onChange={onSelect}
      options={options}
      className={className}
      lightMode={lightMode}
      size={size}
    />
  );
};