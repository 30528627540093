import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AppProvider } from './context';
import { Footer, Header } from './components';
import './i18n/i18n';
import { Routes as LocalRoutes } from './router';

import './App.scss';
import './styles/Flexboxstyle.scss';
import './styles/Commonstyle.scss';
import './styles/Fonts.scss';

const LazyLandingPage = lazy(() =>
  import('./pages/LandingPage/LandingPage').then((module) => ({
    default: module.LandingPage
  }))
);

const LazyBuyPage = lazy(() =>
  import('./pages/BuyPage/BuyPage').then((module) => ({
    default: module.BuyPage
  }))
);

const fallback = <div className="main-background-image"></div>;

function App() {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AppProvider>
      <div className="App">
        <Header/>

        <div className="application-content">
          <Suspense fallback={fallback}>
            <Routes>
              <Route path={LocalRoutes.LANDING_PAGE} element={<LazyLandingPage/>}/>
              <Route path={LocalRoutes.BUY_PAGE} element={<LazyBuyPage/>}/>
            </Routes>
          </Suspense>
        </div>

        <Footer/>
      </div>
    </AppProvider>
  );
}

export default App;
