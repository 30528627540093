import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, Text } from '../../components';
import { TestimonialCardType, TestimonialCard } from './TestimonialCard/TestimonialCard';
import bitcoin1 from "../../assets/crypto/coins/Bitcoin-1.png";
import litecoin3 from "../../assets/crypto/coins/LiteCoin-3.png";
import cameron from '../../assets/user/cameron.png';
import albert from '../../assets/user/albert.png';
import "./Testimonials.scss";

export const Testimonials = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const testimonials: TestimonialCardType[] = [
    {
      user: 'Cameron Williamson',
      occupation: 'Analyst',
      avatar: cameron,
      feedback: 'Tincidunt id nibh orci nibh justo. Purus et turpis nulla elementum, sed vel.',
    },
    {
      user: 'Bessie Cooper',
      occupation: 'Stock Manager',
      feedback: 'Tincidunt id nibh orci nibh justo. Purus et turpis nulla elementum, sed vel.',
    },
    {
      user: 'Albert Flores',
      occupation: 'Crypto Analyst',
      avatar: albert,
      feedback: 'Tincidunt id nibh orci nibh justo. Purus et turpis nulla elementum, sed vel.',
    }
  ];

  return (
    <section id={id} className="testimonials mt-40 mb-40 w-70">
      <Text variant="title2" weight={700} className="mb-10">
        {t('landingPage.testimonials.title')}
      </Text>

      <Text variant="content1" hasOpacity className="mb-30">
        {t('landingPage.testimonials.subtitle')}
      </Text>

      <Carousel>
        {testimonials.map((item: TestimonialCardType) => (
          <TestimonialCard card={item} key={item.user}/>
        ))}
      </Carousel>

      <img src={litecoin3} alt="litecoin" className="coin img-top-left"/>
      <img src={bitcoin1} alt="bitcoin" className="coin img-top-right"/>
    </section>
  );
};