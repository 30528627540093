import { createContext } from 'react';
import { CryptoValue } from '../constants';


export type AppContextType = {
  selectedCrypto: CryptoValue
  setSelectedCrypto: (crypto: CryptoValue) => void
  amountValue: number
  setAmountValue: (value: number) => void;
};

// @ts-ignore
export const AppContext = createContext<AppContextType>({
  selectedCrypto: CryptoValue.BTC,
  setSelectedCrypto: (crypto: CryptoValue) => null,
  amountValue: 0,
  setAmountValue: (value: number) => null,
});