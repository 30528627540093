import { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../router';
import { AppLanguage, LANGUAGES } from '../../i18n';
import { Text } from '../../components';
import { BurgerMenu } from './BurgerMenu/BurgerMenu';
import './Header.scss';

export const Header = memo(
  function Header() {
    const { t, i18n } = useTranslation();
    const [ selectedLang, setSelectedLang ] = useState(AppLanguage.ENGLISH);

    const navItems = [
      { label: t('layout.top-nav.howItWorks'), href: '#' },
      { label: t('layout.top-nav.cryptos'), href: '#avalaible-cryptos-section' },
      { label: t('layout.top-nav.features'), href: '#attractive-features-section' },
      { label: t('layout.top-nav.testimonials'), href: '#testimonials-section' },
      { label: t('layout.top-nav.university'), href: '#crypto-market-analysis-section' },
    ];

    const onChangeLang = useCallback(() => {
      if (selectedLang === AppLanguage.ENGLISH) {
        setSelectedLang(AppLanguage.SPANISH);
        i18n.changeLanguage(AppLanguage.SPANISH);
      } else {
        setSelectedLang(AppLanguage.ENGLISH);
        i18n.changeLanguage(AppLanguage.ENGLISH);
      }
    }, [ selectedLang, setSelectedLang, i18n ]);

    return (
      <nav className="header">
        <div className="nav-content display-flex space-between align-items-center">
          <div className="app-title second-color">
            <Link to={Routes.LANDING_PAGE}>
              <Text variant="content1" color="secondary" weight={700}>
                {t('layout.top-nav.crypto')}
              </Text>
            </Link>
          </div>

          <div className="menu-items display-flex space-between align-items-center gap-20">
            {navItems.map((item, index) => (
              <a href={item.href ?? undefined} key={item.label}>
                <Text variant="content2">
                  {item.label}
                </Text>
              </a>
            ))}
          </div>

          <div className="menu-settings display-flex space-between align-items-center gap-20">
            <Text variant="content2">
              User
            </Text>
            <Text variant="content2" onClick={onChangeLang} className="cursor-pointer">
              {LANGUAGES[selectedLang].code.toUpperCase()}
            </Text>
          </div>

          <BurgerMenu
            navItems={navItems}
            user={{
              value: 'User',
              onClick: () => {
              }
            }}
            language={{
              value: LANGUAGES[selectedLang].code.toUpperCase(),
              onClick: onChangeLang
            }}
          />
        </div>
      </nav>
    );
  }
);