import { Text, Icon } from '../../../components';
import avatarPlaceholder from '../../../assets/user/placeholder.svg';
import './TestimonialCard.scss';

export type TestimonialCardType = {
  user: string,
  occupation: string,
  avatar?: string,
  feedback: string
}

export const TestimonialCard = ({ card }: { card: TestimonialCardType }) => {
  return (
    <div className="carousel-card testimonial-card display-flex-column align-items-center">
      <div className="mb-20">
        <Icon className="icon-quote-outline" size={80} color="secondary" />
      </div>
      <Text variant="caption1" hasOpacity className="mb-20" maxLines={3}>
        {card.feedback}
      </Text>
      <img
        className="avatar display-block mb-10"
        src={card.avatar || avatarPlaceholder}
        alt={`${card.user} avatar`}
      />
      <Text variant="content2" weight={600}>
        {card.user}
      </Text>
      <Text variant="caption2" color="secondary">
        {card.occupation}
      </Text>
    </div>
  );
};