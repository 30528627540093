import { Text } from '../../../components';
import './ArticleCard.scss';

export type ArticleCardType = {
  title: string,
  content: string,
  image: string,
}

export const ArticleCard = ({ article }: { article: ArticleCardType }) => {
  return (
    <div className="article-card display-flex gap-20 mb-30">
      <img
        className="article-image display-block"
        src={article.image}
        alt={`${article.title}`}
      />

      <div>
        <Text variant="content1" weight={600} className="mb-20" align="left">
          {article.title}
        </Text>
        <Text variant="caption1" hasOpacity align="left" maxLines={3}>
          {article.content}
        </Text>
      </div>

    </div>
  );
};