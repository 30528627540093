import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../Text/Text';
import { ArticleCardType, ArticleCard } from './ArticleCard/ArticleCard';
import article1 from '../../assets/articles/article1.png';
import article2 from '../../assets/articles/article2.png';
import article3 from '../../assets/articles/article3.png';
import article4 from '../../assets/articles/article4.png';
import monero1 from '../../assets/crypto/coins/Monero-1.png';
import './CryptoMarketAnalysis.scss';


export const CryptoMarketAnalysis = ({ id }: { id: string }) => {
  const { t } = useTranslation();

  const articles: ArticleCardType[] = [
    {
      title: 'How to buy Bitcoin',
      content: 'Eu tellus quam id sed ultrices. Integer nunc lectus nisi, erat et ',
      image: article1,
    },
    {
      title: 'Gain the best',
      content: 'Eu tellus quam id sed ultrices. Integer nunc lectus nisi, erat et ',
      image: article2,
    },
    {
      title: 'Reduce your loosing',
      content: 'Eu tellus quam id sed ultrices. Integer nunc lectus nisi, erat et ',
      image: article3,
    },
    {
      title: 'Buying strategy',
      content: 'Eu tellus quam id sed ultrices. Integer nunc lectus nisi, erat et ',
      image: article4,
    }
  ];

  return (
    <section id={id} className="crypto-market-analysis mt-40 mb-40 w-70">
      <Text variant="title2" weight={700} className="mb-10">
        {t('landingPage.cryptoMarketAnalysis.title')}
      </Text>

      <Text variant="content1" hasOpacity className="mb-30">
        {t('landingPage.cryptoMarketAnalysis.subtitle')}
      </Text>

      <div className="display-flex flex-wrap align-center gap-20">
        {articles.map((article) => (
          <ArticleCard article={article} key={article.title}/>
        ))}
      </div>

      <img src={monero1} alt="monero" className="coin img-bottom-left"/>
    </section>
  );
};