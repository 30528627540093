import { memo, ReactNode } from 'react';
import './Text.scss';

type TextProps = {
  children: ReactNode,
  variant:
    'title1' | // font-size: 64px
    'title2' | // font-size: 48px
    'subtitle1' | // font-size: 36px
    'subtitle2' | // font-size: 30px
    'content1' | // font-size: 24px
    'content2' | // font-size: 20px
    'caption1' | // font-size: 18px
    'caption2', // font-size: 16px
  color?:
    'primary' | // white
    'secondary' | // pink
    'tertiary' | // black
    'success' | // green
    'error', // red
  align?: 'left' | 'center' | 'right',
  weight?: 500 | 600 | 700,
  hasOpacity?: boolean,
  maxLines?: 1 | 2 | 3 | 4 | 5,
  className?: string,
  onClick?: () => void;
}

export const Text = memo(
  function Text(props: TextProps) {
    const {
      children,
      variant,
      color,
      align,
      weight,
      hasOpacity,
      maxLines,
      className,
      onClick
    } = props;
    const variantClass = `variant-${variant}`;
    const colorClass = color ? `color-${color}` : 'color-primary';
    const alignClass = align ? `align-${align}` : 'align-center';
    const weightClass = weight ? `weight-${weight}` : '';
    const opacityClass = hasOpacity ? 'opacity' : '';
    const maxLinesClass = maxLines ? `max-lines-${maxLines}` : '';

    return (
      <p
        className={`text ${variantClass} ${colorClass} ${alignClass} ${weightClass} ${opacityClass} ${maxLinesClass} ${className}`}
        onClick={onClick}
      >
        {children}
      </p>
    );
  }
);