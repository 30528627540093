import { ReactNode, useState, useMemo } from 'react';
import { AppContext } from './AppContext';
import { CryptoValue } from '../constants';

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [
    selectedCrypto,
    setSelectedCrypto
  ] = useState(CryptoValue.BTC);
  const [
    amountValue,
    setAmountValue
  ] = useState(0);

  const value = useMemo(() => {
    return {
      selectedCrypto,
      setSelectedCrypto,
      amountValue,
      setAmountValue
    };
  }, [ selectedCrypto, setSelectedCrypto, amountValue, setAmountValue ]);


  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};