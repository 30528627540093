import { useTranslation } from 'react-i18next';
import { Text } from '../../Text/Text';
import './FooterSections.scss';

export const FooterSections = () => {
  const { t } = useTranslation();

  const links = [
    { label: t('layout.footer.links.howItWorks'), href: '#' },
    { label: t('layout.footer.links.cryptos'), href: '#' },
    { label: t('layout.footer.links.features'), href: '#' },
    { label: t('layout.footer.links.testimonial'), href: '#' },
    { label: t('layout.footer.links.blogs'), href: '#' },
  ];

  const legal = [
    { label: t('layout.footer.legal.termsOfUse'), href: '#' },
    { label: t('layout.footer.legal.termsOfConditions'), href: '#' },
    { label: t('layout.footer.legal.privacyPolicy'), href: '#' },
    { label: t('layout.footer.legal.cookiePolicy'), href: '#' },
  ];

  return (
    <div className="footer-sections display-flex flex-wrap gap-30">
      <section className="crypto">
        <Text variant="content1" color="secondary" weight={700} className="mb-30" align="left">
          {t('layout.footer.crypto.title')}
        </Text>
        <Text variant="caption1" align="left">
          {t('layout.footer.crypto.subtitle')}
        </Text>
      </section>

      <section className="links">
        <Text variant="content1" color="secondary" weight={700} className="mb-30" align="left">
          {t('layout.footer.links.title').toUpperCase()}
        </Text>
        {links.map((link) => (
          <a href={link.href} key={link.label}>
            <Text variant="caption1" align="left" className="mb-10">
              {link.label}
            </Text>
          </a>
        ))}
      </section>

      <section className="legal">
        <Text variant="content1" color="secondary" weight={700} className="mb-30" align="left">
          {t('layout.footer.legal.title').toUpperCase()}
        </Text>
        {legal.map((link) => (
          <a href={link.href} key={link.label}>
            <Text variant="caption1" align="left" className="mb-10">
              {link.label}
            </Text>
          </a>
        ))}
      </section>

      <section className="newsletter">
        <Text variant="content1" color="secondary" weight={700} className="mb-30" align="left">
          {t('layout.footer.newsletter.title').toUpperCase()}
        </Text>
        <Text variant="content2" align="left" hasOpacity className="mb-20">
          {`${t('layout.footer.newsletter.over')} 2500 ${t('layout.footer.newsletter.peopleHaveSubscribed')}`}
        </Text>
        <Text variant="caption2" align="left" hasOpacity className="mb-20">
          {t('layout.footer.newsletter.doNotSellAndSpam')}
        </Text>
      </section>
    </div>
  );
};